import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
// import { Link } from 'gatsby'

import Layout from '../components/Layout'
import McRae from './../assets/images/McRae.jpg'

class SiteIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )

    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>
        <div
          className="index"
          style={{
            display: `flex`,
            marginTop: `1rem`,
          }}
        >
          <div>
            <h1>Hi, I'm McRae!</h1>
            <p>
              I’m a software engineer with a background in marketing &
              operations.
            </p>
            {/* <p>
              While working in the wine industry, I discovered a love for
              solving problems through code.
            </p> */}
            <p>
              I'm currently a Software Engineer at{' '}
              <a href="https://www.grailed.com/" target="_blank">
                Grailed
              </a>
              .
            </p>
            <a href="mailto:mpetrey@gmail.com" target="_new">
              Get in touch!
            </a>{' '}
          </div>
          <img
            src={McRae}
            alt="profile photo of McRae Petrey"
            className="profile-pic"
            style={{
              maxWidth: `200px`,
              margin: `10px`,
              alignSelf: `flex-start`,
            }}
          />
        </div>
      </Layout>
    )
  }
}

export default SiteIndex

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
